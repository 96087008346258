import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import Divider from '../../sections/akkadian/Divider';
import imgBanner from "../../assets/image/akkadian/adhd_hero.png";
import imgC1 from "../../assets/image/akkadian/adhd_content1.png";
import imgC2 from "../../assets/image/akkadian/adhd_content2.png";
import imgC3 from "../../assets/image/akkadian/adhd_content3.png";
import imgBG from "../../assets/image/akkadian/adhd_bg1.png";
import docInfo from "../../assets/docs/Patient_Info.pdf";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PatientAction from "../../sections/akkadian/PatientBlock";


const ADHDTesting = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >

	
        <Helmet>
          <title>Akkadian Health - ADHD Testing & Diagnosis | Telehealth Australia</title>
          <meta
            name="description"
            content="Affordable ADHD Testing, Assessment, and Diagnosis services in Australia via telehealth. Experts in ADHD."
          />
        </Helmet>

        <div className="inner-banner">
          <Container fluid className="mt-md-10 pt-md-10">
            <Row className="px-sm-0 px-xs-0 px-lg-22 mt-md-6 pr-28 pt-24 pt-lg-29" style={{ backgroundImage: `url(${imgBanner})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
              <Col lg="9" xl="8">
                <div className="section-title px-2 px-lg-12 mb-lg-6 mb-5">
                  <h1 className="title gr-text-1 mb-3 mb-lg-4 text-white">ADHD</h1>
                  <h4 className="title gr-text-7 mb-9 mb-lg-14 text-white">(Attention Deficit Hyperactivity Disorder)</h4>
                </div>
                <div className="section-title px-2 px-lg-12 mb-lg-24 mb-20">
                  <h4 className="title gr-text-3 mb-3 mb-lg-4 text-white">What you need to know</h4>
                </div>                
              </Col>
            </Row>

            <Row className="px-sm-0 px-xs-0 px-lg-10 px-xl-22 mx-lg-18">
              <Col xl="6" className="px-8 px-xl-10 my-8 my-lg-6 my-xl-18" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="rounded-circle d-flex justify-content-center align-items-center"
                  style={{ width: 'auto', height: 'auto', overflow: 'hidden', borderRadius: '50%'}}>
                  <img src={imgC1} alt="ADHD Assessment" className="img-fluid w-70 h-70" style={{ objectFit: 'cover' }} />
                </div>
              </Col>
              <Col xl="6" className="pr-10 pr-xl-10 my-8 my-lg-6 my-xl-18">
                <h2 className="title text-blue gr-text-3 mb-6">
                  Understanding ADHD
                </h2>
                  <p className="pr-xl-12 pr-6 gr-text-7 ml-2 text-black">
                  Attention Deficit Hyperactivity Disorder (ADHD) is a condition where the brain development is different, 
                  it can affect your thinking, communication, and behaviour. It commonly presents as hyperactivity, 
                  impulsiveness, or difficulties with attention, forgetfulness, sitting still and concentration.
                  <br /><br />
                  Signs are often evident before the age of 12, but ADHD can last a lifetime and often goes 
                  unnoticed for years, especially in women. 
                  <br /><br />
                  While ADHD can pose challenges, proper treatment, support, and medication can help you lead a fulfilling life. 
                  Untreated ADHD can be draining and lead to burnout. It’s important to approach treatment and lifestyle changes 
                  gradually and celebrate each success along the way. 
                  <br /><br />
                  There are solutions... and Akkadian Health is here to help you on your journey.
                  </p>
              </Col>
            </Row>


            <Row className="px-sm-0 px-xs-0 px-lg-10 px-xl-22 mx-lg-18">
              <Col xl="7" className="px-9 pl-lg-22 my-9 my-lg-12">
                <p className="text-blue gr-text-4 ml-8 mb-6" style={{ lineHeight: '1.3' , fontWeight: 500}}>
                  We are leading providers of ADHD Testing and Assessments in Australia
                </p>
                <p className="pr-xl-12 pr-6 gr-text-7 ml-9">
                  The first step on your journey and to access our services, is to discuss your health concerns and desire to seek specialist testing with your 
                  regular General Practitioner (GP). 
                  <br />  <br />
                  Your GP might ask you about your medical history and symptoms as the relate to your ADHD concerns.               
                </p>


{/*                 <p className="gr-text-6 ml-8 mb-12 text-black">
                  Download our referral guide* to fast track your assessment.
                </p>                  
                <div className="px-4 text-center mb-12 mb-lg-18">
                  <AnchorLink to={`#guide`}>
                    <Button variant="warning" className="gr-hover-y w-80" style={{backgroundColor: '#ffad00',  padding: '1rem 1rem', fontSize: '1.3rem', fontWeight: 'bold'}}>Download Referral Guide</Button>
                  </AnchorLink>
                </div>   

                <p className="gr-text-12 mx-6 mb-3">
                  * If you already have an electronic copy (or image) of a valid GP referral letter, please send it to us via{' '}
                  <a style={{ color: 'blue' }} href="mailto:reception@akkadianhealth.com">
                    reception@akkadianhealth.com
                  </a>{' '}
                  or ask your GP to send us your referral directly.
                </p> */}

              </Col>
              <Col xl="5" className="px-12 my-9 my-lg-12 my-xl-18" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="rounded-circle d-flex justify-content-center align-items-center"
                  style={{ width: 'auto', height: 'auto', overflow: 'hidden', borderRadius: '50%'}}>
                  <img src={imgC2} alt="Referral Guide" className="img-fluid w-60 h-60" style={{ objectFit: 'cover' }} />
                </div>
              </Col>              
            </Row>


            <Row >
              <Col lg="12" className="my-9 my-lg-18">
                <div className="section-title px-md-8 mb-lg-6 mb-5 text-center">
                  <p className="gr-text-5 mb-3 mb-lg-4 text-blue">Take the first step,</p>
                  <h4 className="gr-text-2 mb-3 mb-lg-4 text-blue">Take our quick quiz today</h4>
                  <p className="gr-text-8 mb-9 mb-lg-14 text-blue">Take our ADHD Symptom Quiz and receive your GP referral guide.</p>
                </div>
                <div className="px-4 text-center mb-8 mb-lg-10">
                  <a href={`https://akkadianhealth-2.snapforms.com.au/form/screening`} target="_blank" rel="noreferrer">
                    <Button variant="warning" className="gr-hover-y w-80" style={{backgroundColor: '#ffad00', padding: '1rem 1rem', fontSize: '1.4rem', fontWeight: 'bold'}}>Take Quiz</Button>
                  </a>
                </div>             
              </Col>
            </Row>
            </Container>
            
            <div className="py-8" style={{backgroundColor: "rgba(255, 173, 0, 0.2)"}}>
            <Container>
              <Row className="py-8">
                <Col>
                  <h2 className="title text-blue gr-text-4 mb-3 text-blue text-center">How is ADHD Diagnosed at Akkadian Health?</h2>
                </Col>
              </Row>
              <Row>
                <Col md={6} className="px-9">
                  <p className="gr-text-7">
                  Take our ADHD quick symptom quiz to receive your GP referral guide (or simply download the guide). Then, ask your GP to send your referral to Akkadian Health as per the referral guide. 
                  <br /><br />
                  Once we have the referral* from your GP, we will contact you to book your appointments with us.                    
                  </p>
                </Col>
                <Col md={6} className="px-9">
                  <p className="gr-text-7">
                  ADHD testing follows the latest AADPA Australian Evidence Based Clinical Practice Guidelines which involves a thorough 
                  and extensive process over two (2) telehealth / video consultation sessions. 
                  <br /><br />
                  Your first session will be with a mental health nurse, followed by a session with a highly experienced psychiatrist for the final diagnosis 
                  and treatment plan for you and your GP to manage going forward.
                  </p>
                </Col>
              </Row>

              <Row className="pt-12">
                <Col md={6}>
{/*                   <p className="py-4 gr-text-6 text-black text-center">
                  <strong>We are here to lighten your mental load                  </strong>
                  </p> */}

                  <p className="gr-text-12 mx-6 mb-3">
                  * If you already have an electronic copy (or image) of a valid GP referral letter, please send it to us via{' '}
                  <a style={{ color: 'blue' }} href="mailto:reception@akkadianhealth.com">
                    reception@akkadianhealth.com
                  </a>{' '}
                  or ask your GP to send us your referral directly.
                </p> 

                </Col>
                <Col md={6}>
                <div className="px-4 text-center mb-12 mb-lg-18">
                <a href={`https://akkadianhealth-2.snapforms.com.au/form/screening`} target="_blank" rel="noreferrer">
                    <Button variant="warning" className="gr-hover-y w-80 mt-6" style={{backgroundColor: '#ffad00', padding: '0.6rem 0.6rem', fontSize: '1.3rem', fontWeight: 'bold'}}>Take Quiz</Button>
                  </a>

                  <AnchorLink to={`#guide`}>
                    <Button variant="outline" className="gr-hover-y w-80 mx-9 mt-6" style={{borderWidth: '4px', borderColor: '#ffad00', padding: '0.6rem 0.6rem', fontSize: '1.1rem', fontWeight: 'bold', color: '#darkgrey'}}>Download Referral Guide</Button>
                  </AnchorLink>
                </div>  
                </Col>
              </Row>

            </Container>
            </div>
            <Container>
            <Row className="pr-xl-10">
              <Col lg="4" className="my-9 my-lg-12">
                <h2 className="title text-blue gr-text-4 mb-3">
                  Why choose Akkadian Health for adult ADHD Assessments?
                </h2>
              </Col>
              <Col lg="8"  className="my-9 my-lg-12">
                <h3 className="gr-text-8">Flexible Payment Options</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                To help you, we offer a ADHD Assessment CARE PACKAGE for <strong>$1280 </strong>upfront (an 8.5% discount) which covers both or your assessment appointments.
                Alternatively, we now offer payment plans with 4 equal payments of <strong> $350 </strong>.
                </p>                
                <p className="gr-text-10 mb-7 mb-lg-10">
                 In both cases, you will most likely receive a $444.90 rebate from Medicare after all services are complete. 
                 Please refer to our <Link to={`/faq#fees`} className={`gr-text-12`}>
                    FAQ
                  </Link>{' '}
                  for the latest information on fees and rebates under "<i>What are your fees?</i>"
                </p>

                <h3 className="gr-text-8">Comprehensive Assessments</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                  Our ADHD Assessments are conducted by highly experienced, RANZCP-registered psychiatrists in
                  Australia using the latest AADPA evidence-based guidelines over two extensive consultation sessions.
                </p>
                <p className="gr-text-10 mb-7 mb-lg-10">
                  Your referring GP (and you) will receive a comprehensive assessment report and treatment plan.
                </p>

                <h3 className="gr-text-8">Convenient, fast & efficient</h3>
                <p className="gr-text-10 mb-3 mb-lg-5">
                We understand the unmet need for adult ADHD assessments, diagnoses and treatment across Australia and are committed to helping you find out if this is your concern.
                </p>
                <p className="gr-text-10 mb-7 mb-lg-10">
                Our ADHD Assessments are delivered through accessible telehealth (video conference) services by our dedicated team of clinicians with minimal wait times of 2-4 weeks.
                </p>

                <h3 className="gr-text-8">Ongoing Support</h3>
                <p className="gr-text-10 mb-7 mb-lg-10">
                We want you to thrive beyond your diagnosis, so offer ongoing support and help from our experienced registered psychologists and ADHD Clinicians.
                </p>
              </Col>
            </Row>

            </Container>


          <div id="guide" className="px-2 text-center py-7 py-lg-10">
                <PatientAction id={"contact"} buttonText={"Request Guide"} title={"Need a Referral for an ADHD Assessment?"} text={"Please submit this form to receive an ADHD referral guide for you and your GP."} />
			 		</div>
          

       <Divider />
      <Container>
			<Row className="justify-content-center py-lg-10 py-8">
			  <Col lg="12">
				<h2 className="title text-blue gr-text-5 mb-7">
					Things You Need to Know Before Proceeding
				</h2>
				
				
				<div>
				<h3 className="gr-text-8">Valid Referrals</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">
				<strong>Referrals must be addressed to 'Akkadian Health'.</strong> They must contain sufficient information about your medical history and presenting complaint (including a request for an ADHD assessment).
				</p>
				<p className="gr-text-12 mb-11 mb-lg-9">
				Please note: We will need your Medicare Number. If you are not registered for Medicare in Australia, you will not be eliible for Medicare rebates which will increase your overall out-of-pocket fee. 
				</p>
				
				<h3 className="gr-text-8">Medication for ADHD</h3>
				<p className="gr-text-12 mb-7 mb-lg-4">
				<strong>Our psychiatrists do not prescribe medication for ADHD.</strong> If you are subsequently diagnosed with ADHD, your comprehensive treatment plan guides your referring GP to prescribe and manage any recommended medication. It is your responsibility to ensure that your GP will enact your treatment plan (which includes initiating the recommended medication) and provide the necessary follow-up care. Our clinicians can work closely with your GP to ensure this happens. Refer to our <Link to={`/faq#meds`} className={`gr-text-12`}> FAQ </Link>: "<i>What if I need medication?</i>" for more information before proceeding.
				</p>
				<p className="gr-text-12 mb-11 mb-lg-9">
				Clients residing in South Australia and diagnosed by Akkadian Health are eligible to receive ADHD medication treatment through our Care Centre - staffed by highly trained and experienced GPs and Nurse Practitioners (with priority access to our treating Psychiatrists). Treatment through our Care Centre is not part of your assessment package and hence additional fees apply. Please contact our reception staff via <a style={{color:'blue'}} href="mailto:care_sa@akkadianhealth.com"> care_sa@akkadianhealth.com</a> to book your first appointment at our Care Centre.
				</p>
				
				<h3 className="gr-text-8">We currently do not accept referrals from residents of NSW</h3>
				<p className="gr-text-12 mb-11 mb-lg-9">Every state in Australia has different health department regulations regarding the prescription of Schedule 8 medication used to treat ADHD. NSW currently does not allow referring GPs to initiate medication. If you are from NSW and you choose to proceed with an ADHD Assessment from Akkadian Health, please contact us first and be aware that you will need to find a local state-based psychiatrist to initiate your medication treatment.
				</p>
				
				<h3 className="gr-text-8">Your ADHD Assessment is conducted over two separate telehealth (video conference) appointments</h3>

					<ul>
					<li>APPOINTMENT 1: MENTAL HEALTH INTAKE & PRE-SCREENING.</li>
					<p className="gr-text-12 mb-7 mb-lg-4"> A 45-minute video call intake and prescreening session conducted by one of Akkadian’s qualified mental health nurses. During your first appointment our mental health nurse will establish your medical history and perform an ADHD pre-assessment. At the end of your appointment the mental health nurse will provide you with a link allowing you to prepay and book your second appointment with one of our ADHD psychiatrists. 
			Note: Our ADHD intake and screen is considered identical to ADHD reports provided by psychologists. There is no need to obtain an ADHD diagnosis and report from a psychologist prior to your appointment with us. </p>
					
					<li>APPOINTMENT 2: PSYCHIATRIC ASSESSMENT/DIAGNOSIS.</li>
					<p className="gr-text-12 mb-7 mb-lg-4">A 45-minute video call assessment with a senior psychiatrist specialising in ADHD. The senior psychiatrist will provide a comprehensive report, management and treatment plan that is sent back to your GP.  </p>
					</ul>
					
					
				</div>
			
			  </Col>
			</Row>				
			
          </Container>
        </div>
        <Divider />
                <div className="px-12 text-center py-7 py-lg-10">
                  <p className="gr-text-8 mb-1">
						Please review our new <Link to={`/faq`} className={`gr-text-9`}> FAQ </Link> for more detailed information.
				  </p>
				 </div>
				 <div className="px-12 text-center mb-8 mb-lg-10">
				 <Link to={`/faq`}>
					<Button variant="warning" className="gr-hover-y w-80 w-sm-auto">FAQ</Button>
				 </Link>
				</div>
        <Divider />
        <Container fluid className="mt-md-10 pt-md-10">
            <Row className="mt-md-6 py-24 py-lg-29" style={{ backgroundImage: `url(${imgBG})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', position: 'relative', height: '100%'}}>
                <div className="section-title text-center px-16 my-lg-29 my-24">
                  <h4 className="gr-text-2 mb-3 mb-lg-4 text-white px-lg-28" style={{ lineHeight: '1.5' }}>We Are Committed To Providing The Highest Quality And Most Efficient Care.</h4>
                </div>                
            </Row>
        </Container>


      </PageWrapper>
    </>
  );
};
export default ADHDTesting;

